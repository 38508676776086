const config = {
  "broker": "3",
  "name": "Italiano",
  "registrationBundle": "italiano",
  "defaultCountryName": "Italy",
  "defaultCountryCode": "IT",
  "fxgeoip_code": "IT",
  languageCode: 'it',
  languageName:'Italian',
  spokenLanguageCode:'it',
  direction:'ltr',
  footerDisclaimer:'896',
  brand: 'iforex'
};
module.exports = config;